import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { baseUrl } from '../config/config';
import "react-lazy-load-image-component/src/effects/blur.css";
import "lightbox2/dist/js/lightbox-plus-jquery";
import "lightbox2/dist/css/lightbox.css";

function Gallery() {

    const [dataG, setDataG] = useState([]);

    useEffect(() => {
        fetchDataG();
    }, []);

    const fetchDataG = async () => {
        const response = await fetch(`${baseUrl}apics/api-list.php`);
        const jsonData = await response.json();

        // Перемешиваем массив и берем первые 10 элементов
        const shuffledData = jsonData.sort(() => 0.5 - Math.random());
        const limitedData = shuffledData.slice(0, 10);

        setDataG(limitedData);
    };

    return (
        <div className="allbox">
            {dataG.map(item => (
                <div className="img-box" key={item.ID} data-years={item.year} data-tech={item.tech} data-img="all">
            {item.selling === '0' && (
                <div className={item.sold === '0' ? 'free' : item.sold === '1' ? 'sold' : 'reserved'}>
                    {item.sold === '0' ? 'Sale' : item.sold === '1' ? 'Fold' : 'Reserved'}
                </div>
            )}
                    <a 
                        href={`${baseUrl}${item.src}`} 
                        data-lightbox='ls' 
                        className='image-link' 
                        title={`${item.title ? item.title : item.name} - ${item.tech}<br/>${item.desc}`}
                    >
                        <div className="name-box">
                            {item.title ? item.title : item.name}
                            <span>
                                {item.tech}
                                <br></br>
                                {item.year}
                            </span>
                        </div>
                        <LazyLoadImage
                            src={`${baseUrl}pics/${item.year}/thumbs/thumb_${item.name}`}
                            alt={item.name}
                            effect="blur"
                            visibleByDefault={false}
                            delayTime={300}
                            className='landscape'
                        />
                    </a>
                </div>
            ))}
        </div>
    );
}

export default Gallery;
