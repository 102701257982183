import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { baseUrl } from '../config/config';
import "react-lazy-load-image-component/src/effects/blur.css";
import "lightbox2/dist/js/lightbox-plus-jquery";
import "lightbox2/dist/css/lightbox.css";

function Gallery({ data, selectedYear, selectedTech }) {
    const filteredData = data.filter(item => {
        if (selectedYear && selectedYear !== 'all' && item.year !== selectedYear) {
            return false;
        }
        if (selectedTech && selectedTech !== 'Все' && !item.tech.split(',').map(tech => tech.trim()).includes(selectedTech)) {
            return false;
        }
        return true;
    });

    return (
        <div className="allbox">
            {filteredData.map(item => (
                <div className="img-box" key={item.ID} data-years={item.year} data-tech={item.tech} data-img="all">
            {item.selling === '0' && (
                <div className={item.sold === '0' ? 'free' : item.sold === '1' ? 'sold' : 'reserved'}>
                    {item.sold === '0' ? 'Sale' : item.sold === '1' ? 'Fold' : 'Reserved'}
                </div>
            )}
                    <a 
                        href={`${baseUrl}${item.src}`} 
                        data-lightbox={`${selectedYear || 'all'}-${selectedTech || 'all'}`} 
                        className='image-link' 
                        title={`${item.title ? item.title : item.name} - ${item.tech}<br/>${item.desc}<br/><span>art-${item.ID}</span>`}
                    >
                        <div className="name-box">
                            {item.title ? item.title : item.name}
                            <span>
                                {item.tech}
                                <br></br>
                                {item.year}
                            </span>
                        </div>
                        <LazyLoadImage
                            src={`${baseUrl}pics/${item.year}/thumbs/thumb_${item.name}`}
                            alt={item.name}
                            effect="blur"
                            visibleByDefault={false}
                            delayTime={300}
                            className='landscape'
                        />
                    </a>
                </div>
            ))}
        </div>
    );
}

export default Gallery;
